<template>
  <validation-observer ref="accountRules" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Session Details</h5>
        <small class="text-muted"> Enter The Session Details. </small>
      </b-col>
      <b-col md="4">
        <b-form-group label="Name" label-for="name">
          <validation-provider #default="{ errors }" name="name" rules="required">
            <b-form-input
              id="name"
              v-model="sessionData.name"
              :state="errors.length > 0 ? false : null"
              placeholder="Jan 1 2022 - DevOps"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Visibility" label-for="visibility">
          <validation-provider #default="{ errors }" name="visibility" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="sessionData.visibility"
              :options="visibilityOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (sessionData.visibility = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Status" label-for="status">
          <validation-provider #default="{ errors }" name="status" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="sessionData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (sessionData.status = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="w-100 m-auto pt-2">
      <hr />

      <b-table :fields="coursesFields" :items="courses" :responsive="true">
        <!-- A custom formatted column -->
        <template #cell(Popularity)="data">
          <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
        </template>

        <template #cell(order_status)="data">
          <b-badge pill :variant="data.value.variant">
            {{ data.value.status }}
          </b-badge>
        </template>

        <!-- A virtual composite column -->
        <template #cell(subject)="data">
          <span v-if="data.value && data.value.label">{{ data.value.label }}</span>
          <span v-else>No Subject Assigned</span>
        </template>

        <!-- A virtual composite column -->
        <template #cell(instructor)="data">
          <span v-if="data.value && data.value.label">{{ data.value.label }}</span>
          <span v-else>No Instructor Assigned</span>
        </template>

        <!-- A virtual composite column -->
        <template #cell(profiles)="data"> {{ data.value.length }} total </template>

        <!-- A virtual composite column -->
        <template #cell(classes)="data"> {{ data.value.length }} total </template>

        <!-- A virtual composite column -->
        <template #cell(operation)="data"> {{ title(data.value) }} </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="startEditCourse(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="startDeleteCourse(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-row>

    <b-row>
      <b-col md="12">
        <hr />

        <b-button :disabled="!isReady" @click="viewSummary" variant="primary" class="float-right">View Summary</b-button>
        <b-button @click="addNewCourse" variant="outline-primary" class="float-right mr-1">Add New Course</b-button>
        <b-button @click="addExistingCourse" variant="secondary" class="float-right mr-1">Add Existing Course</b-button>
      </b-col>
    </b-row>


    <session-add-course
      :is-add-course-open.sync="isAddCourseOpen"
      v-on:attach-course="_addExistingCourse"
      :course="attachingCourse"
    />
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  BRow,
  BButton,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import SessionAddCourse from '../components/SessionAddCourse.vue';
import { required, email } from '@validations';
import useSessionsList from '@/views/apps/roster/sessions-list/useSessionsList';
import { ref, watch } from '@vue/composition-api';
import { title } from '@core/utils/filter';

export default {
  name: 'SessionDetailsTab',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTable,
    BDropdown,
    BDropdownItem,
    SessionAddCourse,
  },
  props: {
    session: {
      type: Object,
    },
    course: {
      type: Object,
    },
    courses: {
      type: Array,
    },
    stage: { type: Number },
  },
  data: () => {
    return {
      coursesFields: [
        { key: 'name', label: 'Name' },
        { key: 'instructor', label: 'Instructor' },
        { key: 'classes', label: 'Classes' },
        { key: 'operation', label: 'Operation' },
        { key: 'actions', label: 'Actions' },
      ],

      isAddCourseOpen: false,
      attachingCourse: {},
    };
  },
  computed: {
    isReady() {
      return this.courses.length > 0 && this.sessionData.name;
    },
  },
  methods: {
    viewSummary() {
      // () => $emit('update:stage', 3)
      this.$emit('update:session', this.sessionData);
      this.$emit('update:stage', 3);
    },
    addNewCourse() {
      this.$emit('update:session', this.sessionData);
      this.$emit('update:course', { isNew: true, visibility: this.sessionData.visibility, status: this.sessionData.status, operation: 'create' });
      this.$emit('update:stage', 1);
    },
    _addExistingCourse(f) {
      console.log('> using existing course', f)

      f.operation = 'attach'
      this.$emit('update:courses', [...this.courses, f])
    },
    addExistingCourse() {
      this.isAddCourseOpen = true;
    },
    startEditCourse(course) {

      if (course.operation === 'attach') {
        const routeData = this.$router.resolve({name: 'apps-roster-course-view', params: { id: course.id }});
        window.open(routeData.href, '_blank');
        return
      }

      const modified = this.courses.filter((k) => k !== course);

      this.$emit('update:courses', [...modified]);

      this.$emit('update:course', { ...course });
      this.$emit('update:stage', 1);
    },
    startDeleteCourse(course) {
      this.$emit('update:courses', this.courses.splice(this.courses.indexOf(course)));
    },
  },
  setup(props, { emit }) {
    const sessionData = ref(props.session);

    watch([sessionData], () => emit('update:session', this.sessionData.value));

    const { statusOptions, visibilityOptions } = useSessionsList();

    return {
      sessionData,

      statusOptions,
      visibilityOptions,
      
      title,
    };
  },
};
</script>

<style scoped></style>
