<template>
  <validation-observer ref="validateCourseDetails" v-slot="{ invalid }" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Class Builder</h5>
        <small class="text-muted">Enter The Details For The Classes To Create.</small>
      </b-col>

      <b-col md="6">
        <b-form-group label="Date" label-for="class-date">
          <validation-provider #default="{ errors }" name="Date" vid="Date">
            <flat-pickr
              id="report-data-date-range"
              class="w-100 form-control"
              :config="{ mode: 'multiple', altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
              :value="classData.$dates"
              @on-change="selectDates"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <validation-provider #default="{ errors }" name="Start Time" rules="required">
          <b-form-group label="Start Time" label-for="class-start-time">
            <b-form-timepicker v-model="classData.timeStart" locale="en" input-id="class-start-time" />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="End Time" rules="required">
          <b-form-group label="End Time" label-for="class-end-time">
            <b-form-timepicker v-model="classData.timeEnd" locale="en" input-id="class-end-time" />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <b-form-group label="Type" label-for="type">
          <validation-provider #default="{ errors }" name="type" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="classData.type"
              :options="classTypeOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (classData.type = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Location" label-for="location">
          <validation-provider #default="{ errors }" name="location" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="classData.location"
              :options="classLocationOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (classData.location = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Visibility" label-for="visibility">
          <validation-provider #default="{ errors }" name="visibility" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="classData.visibility"
              :options="classVisibilityOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (classData.visibility = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Status" label-for="status">
          <validation-provider #default="{ errors }" name="status" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="classData.status"
              :options="classStatusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (classData.status = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <hr />

        <b-button @click="discardChanges" variant="outline-secondary"> Discard </b-button>

        <b-button :disabled="invalid" @click="saveCourse" variant="primary" class="float-right">
          {{ classData.isNew ? 'Add Classes' : 'Save Changes' }}
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  BRow,
  BButton,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import useCoursesList from '@/views/apps/roster/courses-list/useCoursesList';
import { onUnmounted, ref, watch } from '@vue/composition-api';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import userStoreModule from '@/views/apps/user/userStoreModule';
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'ClassDetailsTab',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    flatPickr,
  },
  props: {
    session: {
      type: Object,
    },
    course: {
      type: Object,
    },
    courses: {
      type: Array,
    },
    stage: { type: Number },
  },
  data: () => {
    return {
      previousName: undefined,
      dateRange: '',

      defaultCourse: {
        name: undefined,
        subject: undefined,
        instructor: undefined,
        visibility: 'public',
        status: 'created',
      },

      classTypeOptions: [
        { label: 'Class', value: 'class' },
        { label: 'Lab', value: 'lab' },
      ],
      classLocationOptions: [
        { label: 'Physical', value: 'physical' },
        { label: 'Virtual', value: 'virtual' },
      ],
      classStatusOptions: [
        { label: 'Created', value: 'created' },
        { label: 'Waiting', value: 'waiting' },
        { label: 'Live', value: 'live' },
        { label: 'Completed', value: 'completed' },
      ],
      classVisibilityOptions: [
        { label: 'Internal', value: 'internal' },
        { label: 'Public', value: 'public' },
      ],
    };
  },
  computed: {
    invalid() {
      return false;
    },
  },
  methods: {
    discardChanges() {
      this.$emit('update:stage', 1);
    },
    saveCourse() {
      return new Promise((resolve, reject) => {
        this.$refs.validateCourseDetails.validate().then((success) => {
          if (success) {
            delete this.classData.isNew;

            const { dates } = this.classData;

            let classes = dates.map((date) => {
              const clazz = { ...this.classData };
              delete clazz.dates;

              clazz.date = date;
              return clazz;
            });

            let cd = { classes: [], ...this.course };
            cd.classes.push(...classes);

            console.log(`courseData >>`, cd);

            this.$emit('update:course', JSON.parse(JSON.stringify(cd)));
            this.$emit('update:stage', 1);
            return resolve(true);
          }

          return reject();
        });
      });
    },
    selectDates(dates) {
      if (typeof dates === 'string') {
        this.classData.dates = dates.split(',');
      } else {
        this.classData.dates = dates;
      }
    },
    selectSubject(subject) {
      console.log(`subf`, this.classData, subject);
      this.classData.subject = subject;

      if (!this.classData.name || this.previousName === this.classData.name) {
        this.previousName = subject.label;
        this.classData.name = subject.label;
      }
    },
  },

  mounted() {},
  setup(props, { emit }) {
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    const classData = ref({
      timeStart: '09:00:00',
      timeEnd: '18:00:00',
      type: 'class',
      location: 'virtual',
      status: 'waiting',
      visibility: 'public',
    });

    // watch([classData], () => emit('update:course', classData.value));

    const subjectSuggestions = ref([]);
    const instructorSuggestions = ref([]);

    const { statusOptions, visibilityOptions } = useCoursesList();

    return {
      classData,

      subjectSuggestions,
      instructorSuggestions,

      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
