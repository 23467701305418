import { render, staticRenderFns } from "./SessionAddCourse.vue?vue&type=template&id=2564959e&"
import script from "./SessionAddCourse.vue?vue&type=script&lang=js&"
export * from "./SessionAddCourse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports